import { FC, useEffect, useState } from "react";

import {
  Column,
  FormField,
  Row,
  SectionHeading,
  Switch,
  Textarea,
  TextInput,
  useToast,
  WizardDrawerStep,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { capitalize } from "lodash";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";
import * as y from "yup";

import { RefreshIntervals } from "src/components/audiences/column-settings";
import { getSchemaModelTypeNames } from "src/components/audiences/utils";
import { Explore } from "src/components/explore";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import {
  useCreateObjectMutation,
  useCreateRelationshipMutation,
  useRelationshipModelsQuery,
} from "src/graphql";
import { QueryableSource, QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { ColumnType } from "src/types/visual";
import {
  getModelInputFromState,
  isTimestampColumn,
  useModelRun,
} from "src/utils/models";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";

import { WizardDrawer } from "src/components/drawer";
import { useModelState } from "src/hooks/use-model-state";
import { AssetFields } from "src/pages/schema/components/asset";
import { CatalogFields } from "src/pages/schema/components/catalog";
import { InteractionField } from "src/pages/schema/components/interaction";
import { ParentFields } from "src/pages/schema/components/parent";
import { TimestampField } from "src/pages/schema/components/timestamp-field";
import {
  GENERIC_INTERACTION,
  RelationshipModelTypes,
} from "src/pages/schema/constants";
import {
  EventTimestampModelTypes,
  getSegmentTypeColumn,
} from "src/pages/schema/graph/utils";
import {
  getDefaultRelationship,
  RelationshipFields,
  validationResolver,
} from "src/pages/schema/relationships/relationship";
import {
  AdStatsTimestamp,
  AssetIdField,
  StandardColumnsFields,
} from "../components/ad-stats-fields";
import { getNameFieldTooltip } from "./constants";
import { FormValues } from "./types";

export const CreateSchemaModelWizard: FC<
  Readonly<{
    source?: QueryableSource;
    type: SchemaModelType;
    onCreated?: (id: string) => void;
    fromModelId?: string;
  }>
> = ({ source: initialSource, type, fromModelId, onCreated }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { featureFlags } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { typeName } = getSchemaModelTypeNames(type);
  const { getSlug } = useResourceSlug(SlugResourceType.Segments);
  const [source, setSource] = useState<QueryableSource | undefined>(
    initialSource,
  );
  const [topKEnabled, setTopKEnabled] = useState(
    type === SchemaModelType.Parent
      ? featureFlags.disable_top_k_by_default
        ? false
        : true
      : false,
  );
  const modelState = useModelState({
    query_type: QueryType.Table,
    connection: source,
  });

  const { data: models } = useRelationshipModelsQuery(
    { sourceId: String(source?.id) },
    { select: (data) => data.segments, enabled: Boolean(source) },
  );

  const fromModel = models?.find(
    (model) => String(model.id) === String(fromModelId),
  );

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(modelState.state);

  const isRelationshipRequired = RelationshipModelTypes.includes(type);

  const form = useForm<FormValues>({
    resolver: validationResolver(getFormSchema(type)),
    context: { models, toModel: { columns } },
    defaultValues: {
      name: "",
      description: "",
      relationship: isRelationshipRequired
        ? getDefaultRelationship(String(fromModelId))
        : undefined,
    },
  });
  const { control, handleSubmit, watch, setValue, formState } = form;

  const name = watch("name");
  const timestampColumn = watch("timestamp_column");
  const interaction = watch("interaction_type");

  useEffect(() => {
    if (
      type === SchemaModelType.Event ||
      type === SchemaModelType.Interaction
    ) {
      const hasInteraction = interaction && interaction !== GENERIC_INTERACTION;
      searchParams.set(
        "type",
        hasInteraction ? SchemaModelType.Interaction : SchemaModelType.Event,
      );
      setSearchParams(searchParams);
    }
  }, [interaction]);

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: createRelationship } = useCreateRelationshipMutation();

  const submit: SubmitHandler<FormValues> = async (data) => {
    const {
      relationship,
      timestamp_column,
      interaction_type,
      asset,
      catalog,
      adStats,
      ...values
    } = data;
    const slug = await getSlug(data.name);

    // Check if we should try enabling suggestions for this schema model type.
    // If we're creating columns on a parent model, check the workspace flag. By the default,
    // the flag isn't set, so we'll enable top K in most cases.
    // For any other schema type, don't enable suggestions by default.

    const modelColumns = columns.map((column) => {
      return {
        ...column,
        // Suggestions are generally only useful for strings and sometimes booleans.
        // For all other column types we'll disable it by default.
        top_k_enabled: topKEnabled
          ? [ColumnType.String, ColumnType.Boolean].includes(column?.type)
          : false,
        top_k_sync_interval: RefreshIntervals.Weekly,
      };
    });

    try {
      const response = await createObject({
        input: {
          slug,
          is_schema: true,
          ...getModelInputFromState(modelState.state),
          columns: { data: modelColumns },
          type: getSegmentTypeColumn(type),
          ...(EventTimestampModelTypes.includes(type) && {
            event: {
              data: {
                timestamp_column,
              },
            },
          }),
          ...(type === SchemaModelType.Interaction &&
            interaction_type && {
              interaction: {
                data: {
                  type: interaction_type,
                },
              },
            }),
          ...(type === SchemaModelType.Asset && {
            asset: {
              data: asset,
            },
          }),
          ...(type === SchemaModelType.Catalog && {
            catalog: {
              data: catalog,
            },
          }),
          ...(type === SchemaModelType.AdStats && {
            ad_stats: {
              data: {
                timestamp_column,
                ...adStats,
              },
            },
          }),
          ...values,
        },
      });

      const id = response.insert_segments_one?.id;

      if (isRelationshipRequired) {
        await createRelationship({
          relationship: {
            ...relationship,
            to: String(id),
            from: String(fromModelId),
          },
        });
      }
      toast({
        id: `create-${type}`,
        title: `${capitalize(values.name)} was created`,
        variant: "success",
      });
      if (onCreated) {
        onCreated(id);
      }
    } catch (e) {
      captureException(e);
      toast({
        id: `create-${type}-error`,
        title: `Error creating ${typeName}`,
        variant: "error",
      });
    }
  };

  const steps: WizardDrawerStep[] = [
    {
      label: "Select table",
      isDisabled:
        !modelState.isValid ||
        Boolean(queryError) ||
        (source?.definition?.supportsResultSchema
          ? false
          : !modelState.isPreviewFresh),
      onContinue: async () => {
        if (
          source?.definition?.supportsResultSchema &&
          !modelState.isPreviewFresh
        ) {
          await getSchema();
        }
      },
      render: () => (
        <Column width="100%">
          {source && (
            <Explore
              modelState={modelState}
              cancelQuery={cancelQuery}
              columns={columns}
              isResultTruncated={Boolean(isResultTruncated)}
              numRowsWithoutLimit={numRowsWithoutLimit}
              rows={rows}
              runQuery={runQuery}
              source={source}
              error={queryError}
              errorAtLine={queryErrorAtLine}
              loading={queryLoading}
              rowsPerPage={15}
              overflow="unset"
            />
          )}
        </Column>
      ),
    },
    {
      label: "Configuration",
      continueLabel: `Create ${typeName}`,
      render: () => {
        const timestampColumnOptions = columns
          .filter(isTimestampColumn)
          .map(({ name }) => ({
            value: name,
            label: name,
          }));

        const allColumnOptions: { value: string; label: string }[] =
          columns.map(({ name }) => ({
            value: name,
            label: name,
          }));

        return (
          <FormProvider {...form}>
            <Column gap={6} pb={12}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    label="Name"
                    tip={getNameFieldTooltip(type)}
                    error={error?.message}
                  >
                    <TextInput
                      isInvalid={Boolean(error)}
                      placeholder="Enter a name..."
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    label="Description"
                    isOptional
                    error={error?.message}
                  >
                    <Textarea
                      isInvalid={Boolean(error)}
                      placeholder="Enter a description..."
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </FormField>
                )}
              />
              {type === SchemaModelType.AdStats && (
                <AssetIdField columns={allColumnOptions} />
              )}
              {EventTimestampModelTypes.includes(type) && (
                <>
                  <TimestampField columns={timestampColumnOptions} />
                  <InteractionField columns={allColumnOptions} />
                </>
              )}
              {[
                SchemaModelType.Parent,
                SchemaModelType.Event,
                SchemaModelType.Interaction,
                SchemaModelType.Related,
              ].includes(type) && (
                <Row align="center" gap={3}>
                  <Switch onChange={setTopKEnabled} isChecked={topKEnabled} />

                  <FormField
                    label="Column suggestions"
                    description="Suggestions will cache common column values for selection when building an audience."
                  >
                    {null}
                  </FormField>
                </Row>
              )}
              {type === SchemaModelType.Asset && (
                <AssetFields columns={allColumnOptions} />
              )}
              {type === SchemaModelType.Catalog && (
                <CatalogFields columns={allColumnOptions} />
              )}
              {type === SchemaModelType.Parent && (
                <ParentFields columns={allColumnOptions} />
              )}
              {type === SchemaModelType.AdStats && (
                <>
                  <AdStatsTimestamp columns={allColumnOptions} />
                  <StandardColumnsFields columns={allColumnOptions} />
                </>
              )}

              {isRelationshipRequired && (
                <Column gap={6}>
                  <SectionHeading>Relationship</SectionHeading>
                  {fromModel && (
                    <RelationshipFields
                      toModel={{ name, columns }}
                      fromModel={fromModel}
                      control={control as any}
                      errors={formState.errors as any}
                      watch={watch as any}
                      setValue={setValue as any}
                      sourceId={source!.id}
                    />
                  )}
                </Column>
              )}
            </Column>
          </FormProvider>
        );
      },
    },
  ];

  if (!initialSource) {
    steps.unshift({
      label: "Select source",
      isDisabled: !source,
      render: () => (
        <SourceSelect
          queryType={QueryType.Visual}
          onSelect={(source) => {
            setSource(source);
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (source) {
      modelState.reset(undefined);
    }
  }, [source]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (EventTimestampModelTypes.includes(type) && !timestampColumn) {
      const timestampColumns = columns.filter((column) =>
        isTimestampColumn(column),
      );
      if (timestampColumns.length === 1) {
        const defaultTimestampColumn = timestampColumns[0]?.name;
        setValue("timestamp_column", defaultTimestampColumn, {
          shouldDirty: true,
        });
      }
    }
  }, [type, timestampColumn, columns, setValue]);
  return (
    <WizardDrawer
      isOpen
      size="xl"
      steps={steps}
      title={`New ${typeName}`}
      onClose={() =>
        navigate(`/schema-v2?source=${searchParams.get("source")}`)
      }
      onSubmit={handleSubmit(submit)}
    />
  );
};

export const getFormSchema = (type: SchemaModelType) => {
  const base = {
    name: y.string().label("Name").required(),
    description: y.string().label("Description"),
  };
  if (type === SchemaModelType.Parent) {
    return y.object().shape({
      ...base,
      primary_key: y.string().label("Primary key").required(),
      visual_query_primary_label: y.string().label("Primary label").required(),
      visual_query_secondary_label: y
        .string()
        .label("Secondary label")
        .required(),
    });
  }
  const relationshipSchema = y.object().shape({
    from: y.string(),
    to: y.string(),
    cardinality: y.string().required("Select a cardinality"),
    joinMappings: y
      .array()
      .of(
        y.object().shape({
          to: y.string().required("Select a column"),
          from: y.string().required("Select a column"),
        }),
      )
      .required("Please add valid mappings for joins"),
    isMergingIntoFrom: y.boolean(),
    isMergingIntoTo: y.boolean(),
  });
  if (type === SchemaModelType.Event) {
    return y.object().shape({
      ...base,
      timestamp_column: y.string().label("Timestamp column").required(),
      relationship: relationshipSchema,
    });
  }
  if (type === SchemaModelType.Interaction) {
    return y.object().shape({
      ...base,
      timestamp_column: y.string().label("Timestamp column").required(),
      interaction_type: y.string().label("Event type").required(),
      relationship: relationshipSchema,
    });
  }
  if (type === SchemaModelType.Asset) {
    return y.object().shape({
      ...base,
      asset: y.object().shape({
        type: y.string().label("Asset type").required(),
        primary_key_column: y.string().label("Asset ID").required(),
        primary_label_column: y.string().label("Asset label").required(),
      }),
      relationship: relationshipSchema,
    });
  }
  if (type === SchemaModelType.Catalog) {
    return y.object().shape({
      ...base,
      catalog: y.object().shape({
        primary_label: y.string().label("Primary label").required(),
        secondary_label: y.string().label("Secondary label").required(),
        primary_key: y.string().label("Primary key").required(),
        thumbnail: y.string().label("Thumbnail"),
      }),
    });
  }
  if (type === SchemaModelType.AdStats) {
    return y.object().shape({
      ...base,
      adStats: y.object().shape({
        timestamp_column: y.string().label("Timestamp column").required(),
        asset_id_column: y.string().label("Asset ID").required(),
        spend_column: y.string().label("Spend Column"),
        clicks_column: y.string().label("Clicks Column"),
        impressions_column: y.string().label("Impressions Column"),
      }),
      relationship: relationshipSchema,
    });
  }

  return y.object().shape({
    ...base,
    relationship: relationshipSchema,
  });
};
